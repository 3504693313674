import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';

function App() {
  let filesAdded = '';
  const loadCSS = (csspath) => {
    if (filesAdded.indexOf(csspath) !== -1)
      return
    console.log("Applying css using post message")
    let head = document.getElementsByTagName('head')[0]
    // Creating link element 
    let style = document.createElement('link')
    style.href = csspath;
    style.type = 'text/css'
    style.rel = 'stylesheet'
    head.append(style);

    // Adding the name of the file to keep record.
    filesAdded += csspath
  }

  useEffect(() => {
    console.log("Origin of Iframe",document.referrer)
    window.addEventListener('message', function (event) {
      console.log("Message received");
      // IMPORTANT: Check the origin of the data!
      if (event.origin.startsWith('https://dev.corebusinessone.dca.deloitte.com')) {
        // The data has been sent from your site

        // The data sent with postMessage is stored in event.data
        console.log(event.data);
        loadCSS(event.data)
      } else {
        // The data hasn't been sent from your site!
        // Be careful! Do not use this data.
        return;
      }
    });

  }, [])

  return (
    <div className="App">
      <header className="App-header">
      <h3>Style will be applied from parent by attaching css file using document.domain </h3><br/>
        <div className='container'>
          <button className='btn-primary'>Primary Button</button>
          <br />
          <button className='btn-secondary'>Secondary Button</button>
        </div> <br />
        <h3>Style will be applied by this iframe app dynamically using the css file name which is stored on parent application and path name is shared using window.postMessage </h3>
        <div className='container'>
          <button className='btn-primary1'>Primary Button</button>
          <br />
          <button className='btn-secondary1'>Secondary Button</button>
        </div>
      </header>
    </div>
  );
}

export default App;
